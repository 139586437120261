<template>
  <table class="form-comp" style="border-collapse: collapse; border: 1px #E4E7ED; width: 100%;">
    <div v-for="(item, index) in formData" :key="index">
      <!--    有层级， 标题    -->
      <div v-if="item.type === 'category'">
        <tr class="comp-title" >
          <td class="comp-row" colspan="4" style="width: 100%; padding: 5px 16px;"
              :style="item.level === 1 ? {
                fontSize: '15px',
                fontWeight: 'bolder'
              } : {}">
            <div class="comp-cell">{{item.name}}</div>
          </td>
          <td class="empty-row">
          </td>
          <td class="empty-row">
          </td>
          <td class="empty-row">
          </td>
        </tr>
        <CompleteItem :form-data="item.nodes" :report-id="reportId"></CompleteItem>
      </div>
      <div v-if="item.type === 'data'">
        <tr v-if="item.valueType === 1" class="comp-single" :class="{'empty' : item.status === 3, 'mistake': item.status === 2, 'confirm': item.status === 1}">
          <td class="comp-row" style="width: 29%">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          </td>
          <td class="comp-row" style="width: 25%">
            <div class="comp-cell">
              <div v-if="item.disabled && item.value && item.value.length > 10">
                <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.value">
                  <el-input slot="reference" :disabled="item.disabled" v-model="item.value" size="mini">00</el-input>
                </el-popover>
              </div>
              <div v-else>
                <el-input :disabled="item.disabled" v-model="item.value" size="mini">00</el-input>
              </div>
            </div>
          </td>
          <td class="comp-row" style="width: 11%">
            <div class="comp-cell">
              <el-input :disabled="item.disabled" size="mini" style="visibility: hidden"></el-input>
            </div>
          </td>
          <td class="comp-row" style="width: 35%; padding-left: 10px; padding-right: 10px">
            <div v-if="item.status !== 9" class="comp-cell" style="text-align: center">
              <el-button size="mini" @click="empty(item)">
                <img v-if="item.status === 3" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-empty.svg"
                     alt="">
                缺项
              </el-button>
              <el-button size="mini" type="warning" plain @click="mistake(item)">
                <img v-if="item.status === 2" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-mistake.svg"
                     alt="">
                不同
              </el-button>
              <el-button size="mini" type="primary" @click="confirm(item)">
                <img v-if="item.status === 1" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-confirm.svg"
                     alt="">
                相同
              </el-button>
            </div>
            <div v-else class="comp-cell" style="text-align: center">
              <el-button size="mini" style="width: 80%" type="primary" @click="save(item)">保存</el-button>
            </div>
          </td>
        </tr>
        <tr v-if="item.valueType === 2" class="comp-single" :class="{'empty' : item.status === 3, 'mistake': item.status === 2, 'confirm': item.status === 1}">
          <td class="comp-row" style="width: 29%">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          </td>
          <td class="comp-row" style="width: 25%">
            <div class="comp-cell">
              <el-input :disabled="item.disabled" v-model="item.value" size="mini"></el-input>
            </div>
          </td>
          <td class="comp-row" style="width: 11%">
            <div class="comp-cell">
              <el-input :disabled="item.disabled" class="comp-unit" v-model="item.unit" size="mini"></el-input>
            </div>
          </td>
          <td class="comp-row" style="width: 35%; padding-left: 10px; padding-right: 10px">
            <div v-if="item.status !== 9" class="comp-cell" style="text-align: center">
              <el-button size="mini" @click="empty(item)">
                <img v-if="item.status === 3" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-empty.svg"
                     alt="">
                缺项
              </el-button>
              <el-button size="mini" type="warning" plain @click="mistake(item)">
                <img v-if="item.status === 2" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-mistake.svg"
                     alt="">
                不同
              </el-button>
              <el-button size="mini" type="primary" @click="confirm(item)">
                <img v-if="item.status === 1" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-confirm.svg"
                     alt="">
                相同
              </el-button>
            </div>
            <div v-else class="comp-cell" style="text-align: center">
              <el-button size="mini" style="width: 80%" type="primary" @click="save(item)">保存</el-button>
            </div>
          </td>
        </tr>
        <tr v-if="item.valueType === 3" class="comp-single" :class="{'empty' : item.status === 3, 'mistake': item.status === 2, 'confirm': item.status === 1}">
          <td class="comp-row" style="width: 29%" colspan="2">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          </td>
          <td class="comp-row" style="width: 36%;">
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 24%; margin-right: 2px" v-model="item.values[0].value" size="mini"></el-input>
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 17%; margin-right: 2px" v-model="item.values[0].unit" size="mini"></el-input>
            {{item.seps && item.seps.length > 0 ? item.seps[0] : ''}}
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 24%; margin-right: 2px" v-model="item.values[1].value" size="mini"></el-input>
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 17%" v-model="item.values[1].unit" size="mini"></el-input>
          </td>
          <td class="comp-row" style="width: 35%; padding-left: 10px; padding-right: 10px">
            <div v-if="item.status !== 9" class="comp-cell" style="text-align: center">
              <el-button size="mini" @click="empty(item)">
                <img v-if="item.status === 3" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-empty.svg"
                     alt="">
                缺项
              </el-button>
              <el-button size="mini" type="warning" plain @click="mistake(item)">
                <img v-if="item.status === 2" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-mistake.svg"
                     alt="">
                不同
              </el-button>
              <el-button size="mini" type="primary" @click="confirm(item)">
                <img v-if="item.status === 1" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-confirm.svg"
                     alt="">
                相同
              </el-button>
            </div>
            <div v-else class="comp-cell" style="text-align: center">
              <el-button size="mini" style="width: 80%" type="primary" @click="save(item)">保存</el-button>
            </div>
          </td>
        </tr>
        <tr v-if="item.valueType === 4" class="comp-single" :class="{'empty' : item.status === 3, 'mistake': item.status === 2, 'confirm': item.status === 1}">
          <td class="comp-row" style="width: 29%" colspan="2">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          </td>
          <td class="comp-row" style="width: 36%;">
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 42%; margin-right: 2px" v-model="item.values[0].value" size="mini"></el-input>
<!--            <el-input :disabled="item.disabled" class="comp-unit" style="width: 17%; margin-right: 2px" v-model="item.values[0].unit" size="mini"></el-input>-->
            <span> => </span>
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 42%; margin-right: 2px" v-model="item.values[1].value" size="mini"></el-input>
<!--            <el-input :disabled="item.disabled" class="comp-unit" style="width: 17%" v-model="item.values[1].unit" size="mini"></el-input>-->
          </td>
          <td class="comp-row" style="width: 35%; padding-left: 10px; padding-right: 10px">
            <div v-if="item.status !== 9" class="comp-cell" style="text-align: center">
              <el-button size="mini" @click="empty(item)">
                <img v-if="item.status === 3" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-empty.svg"
                     alt="">
                缺项
              </el-button>
              <el-button size="mini" type="warning" plain @click="mistake(item)">
                <img v-if="item.status === 2" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-mistake.svg"
                     alt="">
                不同
              </el-button>
              <el-button size="mini" type="primary" @click="confirm(item)">
                <img v-if="item.status === 1" style="display: inline-block; width: 11px; height: 11px; position: relative; top: 1px"
                     src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-confirm.svg"
                     alt="">
                相同
              </el-button>
            </div>
            <div v-else class="comp-cell" style="text-align: center">
              <el-button size="mini" style="width: 80%" type="primary" @click="save(item)">保存</el-button>
            </div>
          </td>
        </tr>

      </div>
    </div>
  </table>
</template>

<script>
import bus from '@/libs/utils/eventBus'
import { editReport } from '@/service/module/verify'

export default {
  name: 'CompleteItem',
  props: {
    formData: {
      type: Array,
      default: () => []
    },
    reportId: Number
  },
  methods: {
    empty (i) {
      let isFirst = 1
      if (i.status === 0) { // 第一次点击
        isFirst = 2
      }
      i.status = 3
      this.editReportItem(i, isFirst)
    },
    confirm (i) {
      let isFirst = 1
      if (i.status === 0) { // 第一次点击
        isFirst = 2
      }
      i.status = 1
      this.editReportItem(i, isFirst)
    },
    mistake (i) {
      if (i.status === 0) { // 第一次点击
        bus.$emit('proofreadTotal', 'add')
      }
      i.status = 9
      i.disabled = false
    },
    save (i) {
      i.status = 2
      i.disabled = true
      console.log('不同:', i)
      this.editReportItem(i, 3)
    },
    async editReportItem (item, tag) {
      const params = {
        reportId: this.reportId,
        ...item
      }
      console.log('入参：', params)
      const [err, res] = await this.$to(editReport(params))
      if (res && res.code === 200) {
        this.$message.success('编辑成功')
        if (tag === 2) {
          bus.$emit('proofreadTotal', 'add')
        }
      } else {
        if (tag === 3) {
          bus.$emit('proofreadTotal', 'sub')
        }
        this.$message.error('编辑失败')
      }
      if (err) {
        if (tag === 3) {
          bus.$emit('proofreadTotal', 'sub')
        }
        this.$message.error('网络错误')
        throw new Error(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .form-comp {
    .comp-header {
      border: 1px solid #E4E7ED;
      height: 44px;
    }
    .comp-row {
      border: 1px solid #E4E7ED;
      border-top: none;
      padding: 12px 16px;
      vertical-align: middle;
      &:first-of-type {
        border-left: none;
      }
      &:last-of-type {
        border-right: none;
      }
    }
    .empty-row {
      border-bottom: 1px solid #E4E7ED;
      padding: 12px 16px;
    }
    .comp-title {
      background: #F2F6FC;
      .comp-row {
        border-right: none;
      }
    }
    .comp-single {
    }
    .comp-cell {
      word-break: break-all;
    }
    .border-except-top {
      border: 1px solid #E4E7ED;
      border-top: none;
    }
    .comp-unit {
      ::v-deep .el-input__inner {
        padding: 0 2px;
        text-align: center;
      }
    }
    .confirm {
      background: #ECF5FF;
    }
    .mistake {
      background: #FCF6EC;
    }
    .empty {
      background: #F5F7FA;
    }
    ::v-deep .el-button--warning.is-plain:focus, .el-button--warning.is-plain:hover {
      background: #fdf6ec;
      color: #E6A23C;
    }
    ::v-deep .el-button--default:hover {

    }
  }
</style>
