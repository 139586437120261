export const data = [
  {
    type: 'category',
    name: 'Device Status',
    nodes: [
      {
        type: 'data',
        name: 'Remaining Longevity',
        code: '010018',
        status: 0,
        valueType: 2,
        value: '6.5|years',
        unit: ''
      },
      {
        type: 'category',
        name: 'LV',
        nodes: [
          {
            type: 'data',
            name: 'Lead Impedance',
            code: '010031',
            status: 0,
            valueType: 2,
            value: '10.9',
            unit: 'mV'
          },
          {
            type: 'data',
            name: 'Capture Threshold Measured On',
            code: '010032',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          },
          {
            type: 'data',
            name: 'In-Office Threshold',
            code: '010033',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          },
          {
            type: 'data',
            name: 'Programmed Amplitude/Pulse Width',
            code: '010034',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          },
          {
            type: 'data',
            name: 'Measured P/ R Wave',
            code: '010035',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          },
          {
            type: 'data',
            name: 'In-Office P/R Wave',
            code: '010036',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          },
          {
            type: 'data',
            name: 'Programmed Sensitivity',
            code: '010037',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          }
        ]
      },
      {
        type: 'category',
        name: 'Atrial',
        nodes: [
          {
            type: 'data',
            name: 'Lead Impedance',
            code: '010019',
            status: 0,
            valueType: 2,
            value: '399',
            unit: 'ohms'
          },
          {
            type: 'data',
            name: 'Capture Threshold Measured On',
            code: '010020',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          },
          {
            type: 'data',
            name: 'In-Office Threshold',
            code: '010021',
            status: 0,
            valueType: 3,
            values: [
              {
                valueType: 2,
                value: '0.50',
                unit: 'V'
              },
              {
                valueType: 2,
                value: '0.40',
                unit: 'ms'
              }
            ]
          },
          {
            type: 'data',
            name: 'Programmed Amplitude/Pulse Width',
            code: '010022',
            status: 0,
            valueType: 3,
            values: [
              {
                valueType: 2,
                value: '2.50',
                unit: 'V'
              },
              {
                valueType: 2,
                value: '0.40',
                unit: 'ms'
              }
            ]
          },
          {
            type: 'data',
            name: 'Measured P/ R Wave',
            code: '010023',
            status: 0,
            valueType: 2,
            value: '1.4',
            unit: 'mV'
          },
          {
            type: 'data',
            name: 'In-Office P/R Wave',
            code: '010024',
            status: 0,
            valueType: 2,
            value: '2.3',
            unit: 'mV'
          },
          {
            type: 'data',
            name: 'Programmed Sensitivity',
            code: '010025',
            status: 0,
            valueType: 2,
            value: '0.45',
            unit: 'mV'
          }
        ]
      },
      {
        type: 'category',
        name: 'RV',
        nodes: [
          {
            type: 'data',
            name: 'Lead Impedance',
            code: '010026',
            status: 0,
            valueType: 2,
            value: '418',
            unit: 'ohms'
          },
          {
            type: 'data',
            name: 'Capture Threshold Measured On',
            code: '010027',
            status: 0,
            valueType: 2,
            value: '',
            unit: ''
          },
          {
            type: 'data',
            name: 'In-Office Threshold',
            code: '010028',
            status: 0,
            valueType: 3,
            values: [
              {
                valueType: 2,
                value: '0.50',
                unit: 'V'
              },
              {
                valueType: 2,
                value: '0.40',
                unit: 'ms'
              }
            ]
          },
          {
            type: 'data',
            name: 'Programmed Amplitude/Pulse Width',
            code: '010029',
            status: 0,
            valueType: 3,
            values: [
              {
                valueType: 2,
                value: '2.50',
                unit: 'V'
              },
              {
                valueType: 2,
                value: '0.40',
                unit: 'ms'
              }
            ]
          },
          {
            type: 'data',
            name: 'Measured P/ R Wave',
            code: '010030',
            status: 0,
            valueType: 2,
            value: '0.90',
            unit: 'mV'
          },
          {
            type: 'data',
            name: 'In-Office P/R Wave',
            code: '010031',
            status: 0,
            valueType: 2,
            value: '10.9',
            unit: 'mV'
          },
          {
            type: 'data',
            name: 'Programmed Sensitivity',
            code: '010030',
            status: 0,
            valueType: 2,
            value: '0.90',
            unit: 'mV'
          }
        ]
      }

    ]
  }
]
