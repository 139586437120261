<template>
  <div class="proofread">
    <div class="header">
      <div class="header-info">
        <div class="info-title">请对照程控PDF文件校对左侧数据项</div>
        <el-divider direction="vertical"></el-divider>
        <div class="info-subtitle">程控信息</div>
      </div>
      <div>
        <el-button class="header-btn" type="normal" @click="goBack">返回</el-button>
        <el-button class="header-btn" type="primary" @click="sendForm">提交</el-button>
      </div>
    </div>
    <div class="proofread-body">
      <div class="pdf-data">
        <div class="form-comp__header">
          <div class="header-icon"></div>
          <div class="header-title">程控信息</div>
        </div>
        <div class="form-comp">
          <el-scrollbar style="height: 100%">
            <CompleteForm v-if="show" :form-data="pdfData" :report-id="reportId"></CompleteForm>
          </el-scrollbar>
        </div>
        <div class="empty-bottom" style="height: 16px; flex-shrink: 0"></div>
      </div>
      <div class="pdf-view">
        <div class="form-comp__header">
          <div class="header-icon"></div>
          <div class="header-title">程控报告.pdf</div>
        </div>
        <div class="pdf-view__content">
          <el-scrollbar style="height: 100%">
          <div v-if="showPdf" class="pdf-wrap">
            <pdf v-for="i in numPages" :key="i" class="test" :src="loadingTask" :page="i"></pdf>
          </div>
          </el-scrollbar>
        </div>
        <div class="empty-bottom" style="height: 16px; flex-shrink: 0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { data } from './form'
import CompleteForm from './components/CompleteForm'
import pdf from 'vue-pdf'
import bus from '@/libs/utils/eventBus'
import _ from 'lodash'
import { getReportDetail, finishReport } from '@/service/module/verify'

const moment = require('moment')

export default {
  components: {
    pdf,
    CompleteForm
  },
  data () {
    return {
      moment,
      data,
      pdfData: [],
      show: false,
      numPages: undefined,
      loadingTask: null,
      timeout: null,
      showPdf: false,
      targetLength: 0,
      proofreadLength: 0,
      reportId: null
    }
  },
  mounted () {
    this.show = true
    this.reportId = +(this.$route.query.reportId || '')
    this.getData()

    console.log(this.reportId)
    bus.$on('proofreadTotal', (type) => {
      if (type === 'add') {
        this.proofreadLength += 1
      }
      if (type === 'sub') {
        this.proofreadLength -= 1
      }
      console.log('校验总数', this.proofreadLength)
    })

    this._send('$pageView', '请对照程控PDF文件校对左侧数据项进入情况', '请对照程控PDF文件校对左侧数据项页面 加载成功时触发', {
      accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
      doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
      hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
    })
  },
  methods: {
    async getData () {
      const [err, res] = await this.$to(getReportDetail({
        recordId: this.reportId,
        audit: true
      }))
      if (res && res.code === 200) {
        this.pdfData = res.data.data.nodes
        this.reportId = res.data.id
        const target = _.cloneDeep(this.deepEach(this.pdfData))
        this.targetLength = target.length
        this.pdfInit(res.data.url)
        target.forEach(item => {
          if (item.status !== 0) {
            this.proofreadLength++
          }
        })
        console.log('target:', target)
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    deepEach (nodes) {
      if (!nodes.length) {
        return
      }
      const arr = []
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if (node.type && node.type === 'data') {
          this.$set(node, 'disabled', true)
          this.$set(node, 'audit', true)
          arr.push(node)
        }
        if (node.nodes && node.nodes.length) {
          arr.push(...this.deepEach(node.nodes))
        }
      }
      return arr
    },
    pdfInit (url) {
      const newUrlArr = url.split(':')
      if (newUrlArr[0] === 'http') {
        newUrlArr[0] = 'https'
      }
      const newUrl = newUrlArr.join(':')

      setTimeout(() => {
        this.loadingTask = pdf.createLoadingTask(newUrl)
        this.loadingTask.promise.then(pdf => {
          this.timeout = setTimeout(() => {
            this.numPages = pdf.numPages
            this.showPdf = true
          }, 50)
        }).catch(e => {
          console.log('pdf解析失败:', e)
        })
      }, 200)
    },
    goBack () {
      if (this.$route.query.from) {
        this.$router.replace({
          path: '/patientFiles',
          query: {
            patientId: this.$route.query.patientId
          }
        })
      } else {
        this.$router.replace({
          path: '/pendingTask'
        })
      }
    },
    async sendForm () {
      console.log('审核数:', this.proofreadLength)
      console.log('总数:', this.targetLength)
      if (this.proofreadLength !== this.targetLength) {
        this.$message.error('请核验全部指标')
        return false
      }
      const [err, res] = await this.$to(finishReport(this.reportId))
      if (res && res.code === 200) {
        this.$message.success('提交成功')
        this.$router.replace({
          path: '/pendingTask'
        })
      } else {
        this.$message.error('提交失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../style/common";
  .proofread {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    position:absolute;
    top:0;
    left:0;
    min-width: 1100px;padding: 0 17px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      &-info {
        display: flex;
        align-items: center;
        .info-title {
          @include fs(#131414, 20px, 700);
        }
        .info-subtitle {
          @include fs(#606266, 16px, 400);
        }
        ::v-deep .el-divider--vertical {
          margin: 0 12px;
        }
      }
      &-btn {
        width: 68px;
      }
    }
    .proofread-body {
      flex: 1;
      display: flex;
      overflow: hidden;
      .pdf-data, .pdf-view {
        .form-comp {
          &__header {
            flex-shrink: 0;
            height: 44px;
            border: 1px solid #E4E7ED;
            border-radius: 4px 4px 0 0;
            display: flex;
            align-items: center;
            padding-left: 16px;
            .header-icon {
              @include domSize(24px, 24px);
              background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-header.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 6px;
            }
            .header-title {
              @include fs(#0F1114, 16px, 500);
            }
          }
        }
      }

      .pdf-data {
        /*flex: 6;*/
        width: 650px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        .form-comp {
          overflow: hidden;
          border: 1px solid #E4E7ED;
          border-top: none;
        }
      }
      .pdf-view {
        flex: 1;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        .form-comp {
          &__header {
            flex-shrink: 0;
            border: 1px solid #E4E7ED;
            .header-icon {
              background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-header_pdf.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .pdf-view__content {
          flex: 1;
          overflow: hidden;
          border: 1px solid #E4E7ED;
          border-top: none;
        }
      }
    }
  }
</style>
